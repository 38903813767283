
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import Button from '@/components/blocks/Button.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Form from '@/components/blocks/Form.vue';
    import { AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        inner: true,
        title: 'Блок не нашли цвет',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок'
            },
            text: {
                type: AvailableTypes.string,
                title: 'Текст'
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        }
    };

    @Component({
        components: { Form, OptionsFieldsInlineEditor, Button }
    })
    export default class FormColorNotFound extends Vue {
        @Prop() component;
        @Prop() form;
        @Prop() text;
        @Prop() head;
    }
