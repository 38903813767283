
    import { Component, Prop } from 'nuxt-property-decorator';
    import BreadCrumbs from '@/components/blocks/BreadCrumbs.vue';
    import { mixins } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import Button from '@/components/blocks/Button.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Form from '@/components/blocks/Form.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta,
        ComponentMetaOption,
        ComponentMetaOptionComponent
    } from '@/components/editor/config/types';
    import striptags from 'striptags';

    interface ComponentMetaHeaderBlock extends ComponentMeta {
        options: {
            hidePath: ComponentMetaOption;
            head: ComponentMetaOption;
            text: ComponentMetaOption;
            img: ComponentMetaOption;
            form: ComponentMetaOptionComponent;
        };
    }

    export const meta: ComponentMetaHeaderBlock = {
        title: 'Главный блок',
        options: {
            hidePath: {
                title: 'Скрывать хлебные крошки',
                type: AvailableTypes.boolean,
                default: false
            },
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Производство футболок для бизнеса на заказ'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'При размещении заказа возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, ' +
                    'или нужны уникальные футболки для конкретной группы людей.'
            },
            img: {
                type: AvailableTypes.image,
                title: 'Картинка',
                default: {
                    src: '/block-default/HeaderBlock/t-shirts-main.png'
                }
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Обложка
    };
    @Component({
        components: {
            Form,
            OptionsFieldsInlineEditor,
            Button,
            BreadCrumbs,
            ImageComponent
        }
    })
    export default class HeaderBlock extends mixins(PaletteMixin, PageBlockMixin) {
        @Prop() img;
        @Prop() form;
        @Prop() component;
        @Prop() hidePath;

        striptag = striptags;
    }
