
    import { Component, namespace, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { mixins } from 'nuxt-property-decorator';
    import Button from '@/components/blocks/Button.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Посты из Instagram',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Instagram'
            },
            showRows: {
                type: AvailableTypes.string,
                title: 'Количество отображаемых строк',
                default: '2'
            },
            columns: {
                title: 'Число колонок',
                type: AvailableTypes.select,
                values: [
                    { selected: true, text: '4', value: 3 },
                    { selected: false, text: '3', value: 4 }
                ]
            },
            items: {
                title: 'Ссылки',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.string
                }
            }
        },
        group: AvailableGroups.Другое
    };

    const collections = namespace('collections');

    @Component({
        components: { InlineSlider, Button, ImageComponent }
    })
    export default class InstagramPosts extends mixins(PageBlockMixin) {
        @Prop() items;
        @Prop() showRows;
        @Prop() columns!: number;
        @collections.Action get;
        @collections.Action post;

        displayedItemsCount = 0;
        media = [] as any;

        private readonly lineLength = this.columns === 4 ? 3 : 4;

        get hasMore(): boolean {
            return this.displayedItemsCount < this.items.length;
        }

        get displayedItems() {
            if (this.media['media']) {
                return this.media.media.slice(0, this.displayedItemsCount);
            }
            return [];
        }

        created() {
            if (this.showRows) {
                this.displayedItemsCount = this.lineLength * parseInt(this.showRows);
            } else {
                this.displayedItemsCount = this.lineLength;
            }

            this.loadMedia();
        }

        loadMore() {
            this.displayedItemsCount += this.lineLength;
        }

        async loadMedia() {
            if (this.items.length === 1 && !this.items[0]) {
                const itemsArr = await this.get({
                    path: '/instagram/media/latest'
                });

                this.items = itemsArr.items;
            }

            this.media = await this.post({
                path: '/instagram/media',
                conditions: { posts: this.items }
            });
        }

        get slideItems() {
            if (this.media['media']) {
                return this.media['media'].map(item => {
                    return {
                        img: {
                            src: item.img
                        },
                        text: item.text,
                        href: item.href
                    };
                });
            }
        }
    }
