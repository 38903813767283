
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Button from '@/components/blocks/Button.vue';
    import ImageMixin from '@/mixins/imageMixin';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import Modal from '@/components/blocks/Modal.vue';
    import WindowMixin from '@/mixins/windowMixin';
    import BaseSlider from '@/components/common/BaseSlider.vue';

    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export const meta: ComponentMeta = {
        title: 'Блок с карточками-изображениями',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.editor,
                default: 'Последние проекты'
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    'При размещении заказа возникают следующие проблемы: \n' +
                    'вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей.'
            },
            images: {
                type: AvailableTypes.arrayOfType,
                title: 'Изображения',
                item: {
                    type: AvailableTypes.galleryImage
                },
                default: [
                    {
                        src: '/placeholder-362x362.png',
                        size: '362x362'
                    },
                    {
                        src: '/placeholder-362x204.png',
                        size: '362x204'
                    },
                    {
                        src: '/placeholder-362x204.png',
                        size: '362x204'
                    },
                    {
                        src: '/placeholder-362x204.png',
                        size: '362x204'
                    },
                    {
                        src: '/placeholder-166x166.png',
                        size: '166x166',
                        margin: true
                    },
                    {
                        src: '/placeholder-166x166.png',
                        size: '166x166'
                    },
                    {
                        src: '/placeholder-362x204.png',
                        size: '362x204'
                    },
                    {
                        src: '/placeholder-166x166.png',
                        size: '166x166',
                        margin: true
                    },
                    {
                        src: '/placeholder-166x166.png',
                        size: '166x166'
                    },
                    {
                        src: '/placeholder-362x204.png',
                        size: '362x204'
                    },
                    {
                        src: '/placeholder-362x204.png',
                        size: '362x204'
                    },
                    {
                        src: '/placeholder-362x362.png',
                        size: '362x362'
                    }
                ]
            },
            buttonText: {
                title: 'Название кнопки',
                type: AvailableTypes.string,
                default: 'Другие работы'
            },
            buttonLink: {
                title: 'Ссылка',
                type: AvailableTypes.string,
                default: ''
            },
            targetBlank: {
                title: 'Открывать ссылку в новом окне',
                type: AvailableTypes.boolean,
                default: false
            },
            modalText: {
                title: 'Текст модального окна',
                type: AvailableTypes.string,
                default: ''
            }
        },
        group: AvailableGroups.Галерея
    };

    @Component({
        components: {
            ImageComponent,
            Button,
            OptionsFieldsInlineEditor,
            Modal,
            BaseSlider,
            InlineSlider
        }
    })
    export default class ImagesCardsBlock extends mixins(
        PageBlockMixin,
        ImageMixin,
        WindowMixin
    ) {
        @Prop() buttonText;
        @Prop() component;
        @Prop() images;
        @Prop() modal;
        @Prop() buttonLink;
        @Prop() targetBlank;
        @Prop() modalText;
        modalId = 'modalSlider' + Math.random();
        pictureIndex = null;

        windowWidth = 0;

        getSize(image) {
            if (image.size) {
                return image.size.split('x');
            }
            return ['', ''];
        }

        openModal(picIndex) {
            this.pictureIndex = picIndex;
            this.$bvModal.show(this.modalId);
        }

        beforeMount() {
            if (window !== undefined) {
                this.windowWidth = window.innerWidth;
            }
        }

        target() {
            return this.targetBlank ? '_blank' : '_self';
        }
    }
