
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Color from '@/components/blocks/colors/Color.vue';
    import Accordion from '@/components/blocks/Accordion.vue';
    import ColorsTabs from '@/components/blocks/base/ColorsTabs.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';

    export interface IMaterial {
        name: string;
        description: string;
        colors: [];
    }

    export const meta: ComponentMeta = {
        title: 'Материалы и цвета',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.string
            },
            categories: {
                title: 'Категория материалов',
                type: AvailableTypes.array,
                item: {
                    name: {
                        type: AvailableTypes.string,
                        title: 'Наименование категории'
                    },
                    materials: {
                        type: AvailableTypes.array,
                        title: 'Цвета',
                        item: {
                            colorGroupId: {
                                type: AvailableTypes.select,
                                title: 'Выберите Группу',
                                values: {
                                    type: AvailableTypes.apiSource,
                                    path: '/entities/colors/',
                                    method: 'get',
                                    conditions: {},
                                    mappings: {
                                        value: 'id',
                                        text: 'options.name'
                                    }
                                }
                            },
                            description: {
                                type: AvailableTypes.string,
                                title: 'Описание'
                            }
                        }
                    }
                } as any
            }
        },
        group: AvailableGroups.Цвета
    };

    @Component({
        components: { ColorsTabs, Accordion, Color }
    })
    export default class MaterialsAndColors extends mixins(PaletteMixin, PageBlockMixin) {
        @Prop() categories;
        @Prop() component;
    }
