
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import FooterSocial from '@/components/blocks/footer/FooterSocial.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Menu from '@/components/blocks/menu/Menu.vue';

    export const meta: ComponentMeta = {
        title: 'Футер',
        options: {
            logo: {
                title: 'Логотип - текст',
                type: AvailableTypes.string,
                default: 'PINQ'
            },
            logoImage: {
                title: 'Логотип - изображение',
                type: AvailableTypes.image
            },
            logoText: {
                title: 'Логотип - подпись',
                type: AvailableTypes.editor,
                default: 'Производство текстильной продукции с 2004 года'
            },
            infoText: {
                title: 'Информационный текст',
                type: AvailableTypes.editor,
                default: 'ООО «Футболки оптом» <br> ИНН: 7720341783 <br> КПП: 772001001'
            },
            agreement: {
                title: 'Соглашение',
                type: AvailableTypes.editor,
                default:
                    'Вводя номер телефона и заказывая звонок, вы соглашаетесь на обработку ваших ' +
                    "персональных данных организацией <br>ООО «Футболки оптом»<br><a target='_blank' href=''>Текст соглашения</a>"
            },
            copyright: {
                title: 'Копирайты',
                type: AvailableTypes.editor,
                default: '© PINQ'
            },
            mainMenu: {
                title: 'Меню',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.menuLink
                },
                default: [
                    {
                        name: 'о компании',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'услуги',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'контакты',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'faq',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'наши работы',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'вакансии',
                        href: '',
                        targetBlank: false
                    }
                ]
            },
            infoMenu: {
                title: 'Информационное меню',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.menuLink
                },
                default: [
                    {
                        name: 'Техническая информация',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'Таблица размеров',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'Требования к макетам',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'Карта цветов',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'Условия доставки',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'Вакансии',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'Карта сайта',
                        href: '',
                        targetBlank: false
                    }
                ]
            },
            contactsText: {
                title: 'Контакты',
                type: AvailableTypes.editor,
                default:
                    '<p>111622, г. Москва, <br>ул. Большая Косинская, д. 27, оф. 1013<br>БЦ «Косинская мануфактура»</p><br><p>с 09:30 до 18:00 по Москве </p><a href="tel:+7(495)2155532" class="text-decoration-none">+7 (495) 215-55-32</a><br><a href="mailto:pinq@pinq.ru">pinq@pinq.ru</a>'
            },
            social: {
                title: 'Социальные сети',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.string
                },
                default: [
                    'https://vk.com/pinq_ru',
                    'https://www.facebook.com/pinq.ru/',
                    'https://www.instagram.com/pinq.ru/',
                    'https://www.youtube.com/channel/UCYGT_n0SKIrxbSQ9hm5efZg'
                ]
            },
            showArticles: {
                title: 'Выводить последние статьи',
                type: AvailableTypes.boolean,
                default: true
            }
        },
        group: AvailableGroups.Футер
    };
    @Component({
        components: { Menu, OptionsFieldsInlineEditor, FooterSocial }
    })
    export default class Footer extends Vue {
        @Prop() component;
        @Prop() social;
        @Prop() logo;
        @Prop() logoText;
        @Prop() agreement;
        @Prop() infoText;
        @Prop() copyright;
        @Prop() mainMenu;
        @Prop() infoMenu;
        @Prop() contactsText;
        @Prop() showArticles;
    }
