
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    // @ts-ignore
    import BaseHeader from '@/components/common/BaseHeader';

    export const meta = {
        title: 'Хедер',
        options: {
            logo: {
                title: 'Логотип - текст',
                type: AvailableTypes.string,
                default: 'PINQ'
            },
            logoHref: {
                title: 'Логотип - ссылка',
                type: AvailableTypes.string,
                default: '/'
            },
            logoImage: {
                title: 'Логотип - изображение',
                type: AvailableTypes.image
            },
            items: {
                title: 'Меню',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.deepMenuLink
                },
                default: [
                    {
                        name: 'о компании',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'услуги',
                        href: '',
                        targetBlank: false,
                        submenu: [
                            {
                                icon: {
                                    src: '/block-default/Header/Layer7.svg'
                                },
                                head: {
                                    name: 'Одежда',
                                    href: '',
                                    targetBlank: false
                                },
                                links: [
                                    {
                                        name: 'Футболки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Свитшоты',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Толстовки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Рубашки поло',
                                        href: '',
                                        targetBlank: false
                                    }
                                ]
                            },
                            {
                                icon: {
                                    src: '/block-default/Header/Layer3.svg'
                                },
                                head: {
                                    name: 'Для дома',
                                    href: '',
                                    targetBlank: false
                                },
                                links: [
                                    {
                                        name: 'Пледы',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Подушки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Прихватки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Фартуки',
                                        href: '',
                                        targetBlank: false
                                    }
                                ]
                            },
                            {
                                icon: {
                                    src: '/block-default/Header/Layer4.svg'
                                },
                                head: {
                                    name: 'Аксессуары',
                                    href: '',
                                    targetBlank: false
                                },
                                links: [
                                    {
                                        name: 'Сумки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Рюкзаки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Банданы',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Платки',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Панамы',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Шарфы',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Повязки на голову',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Баффы',
                                        href: '',
                                        targetBlank: false
                                    }
                                ]
                            },
                            {
                                icon: {
                                    src: '/block-default/Header/Layer2.svg'
                                },
                                head: {
                                    name: 'Нанесение',
                                    href: '',
                                    targetBlank: false
                                },
                                links: [
                                    {
                                        name: 'Шелкография',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Сублимация',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Вышивка',
                                        href: '',
                                        targetBlank: false
                                    },
                                    {
                                        name: 'Этикетки / тесьма',
                                        href: '',
                                        targetBlank: false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'контакты',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'faq',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'наши работы',
                        href: '',
                        targetBlank: false
                    },
                    {
                        name: 'вакансии',
                        href: '',
                        targetBlank: false
                    }
                ]
            },
            phone: {
                title: 'Телефон',
                type: AvailableTypes.string,
                default: '+7 (495) 215 55 32'
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default: 'Вы можете уже сейчас сделать заказ!'
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        style: { height: '8rem', position: 'relative', 'z-index': 4 },
        group: AvailableGroups.Хедер
    };
    @Component({
        components: { BaseHeader }
    })
    export default class Header extends Vue {
        @Prop() component;
        @Prop() logo;
        @Prop() logoImage;
        @Prop() logoHref;
        @Prop() items;
        @Prop() phone;
        @Prop() text;
        @Prop() form;
    }
