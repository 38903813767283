
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ImageMixin from '@/mixins/imageMixin';
    import WindowMixin from '@/mixins/windowMixin';

    @Component
    export default class ImageComponent extends mixins(ImageMixin, WindowMixin) {
        @Prop() img;
        @Prop() src;
        @Prop() title;
        @Prop() alt;
        @Prop() preload: boolean | undefined;
        @Prop() controls: boolean;

        getParam(param) {
            if (this.img) {
                return this.img[param];
            }
            return this[param];
        }

        getPreloadValue(defaultValue) {
            return this.preload === undefined ? defaultValue : this.preload;
        }

        isGif() {
            return this.getParam('src').indexOf('.gif') !== -1;
        }

        isPng() {
            return this.getParam('src').indexOf('.png') !== -1;
        }

        get isVideo() {
            return this.getParam('src').indexOf('.mp4') !== -1;
        }

        playVideo() {
            // @ts-ignore
            !this.controls && this.getParam('playOnScroll') && this.$refs.video.play();
        }

        stopVideo() {
            // @ts-ignore
            !this.controls && this.getParam('playOnScroll') && this.$refs.video.pause();
        }
    }
