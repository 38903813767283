
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Текст и изображение',
        options: {
            image: {
                type: AvailableTypes.image,
                title: 'Картинка',
                default: {
                    src: '/block-default/ImageAndText/materials.jpg'
                }
            },
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Материалы'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'Для пошива используем турецкое полотно от постоянных поставщиков качества пенье, 100% хлопок и с добавлением лайкры. Плотность ткани от 145 до 200 г/кв.м. Футболки держат форму после стирки, не линяют, не выгорают.' +
                    'Для пошива используем турецкое полотно от постоянных поставщиков качества пенье, 100% хлопок и с добавлением лайкры. Плотность ткани от 145 до 200 г/кв.м. Футболки держат форму после стирки, не линяют, не выгорают.'
            }
        },
        group: AvailableGroups.Изображение
    };

    @Component({
        components: { OptionsFieldsInlineEditor, ImageComponent }
    })
    export default class ImageAndText extends PageBlockMixin {
        @Prop() image;
        @Prop() component;
    }
