
    import { Component, Prop, mixins, Watch } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import ImageMixin from '@/mixins/imageMixin';
    import PaletteMixin from '@/mixins/paletteMixin';
    import WindowMixin from '@/mixins/windowMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            ImageComponent
        }
    })
    export default class InlineSlider extends mixins(
        ImageMixin,
        PaletteMixin,
        WindowMixin
    ) {
        @Prop() wrapperClass;
        @Prop() slideClass;
        @Prop() items;
        @Prop() imageClasses;
        @Prop({ default: 4 }) countSlides;
        @Prop({ default: '47.125rem' }) sliderWidth;
        @Prop({ default: 1 }) countSlidesMobile;
        @Prop({ default: 'slider-block' }) prefix;
        @Prop({ default: false }) customPaginator;
        @Prop({ default: false }) showNav;
        @Prop({ default: false }) showNavOnMobile;
        @Prop({ default: false }) thumbnails;
        @Prop({ default: false }) showPagination;
        @Prop({ default: false }) showPaginationMobile;
        @Prop({ default: false }) fade;
        @Prop({ default: 'carousel' }) type;
        @Prop({ default: 0 }) initialSlide;
        @Prop() component;
        @Prop({ default: 0 }) activeSlide!: any;
        @Prop({ default: false }) bulletsMobile;
        @Prop() FocusAt;

        slickId = (Math.floor(Math.random() * (1000 - 1)) + 1).toString();

        breakpoints = {
            992: {
                perView: 1
            }
        };
        windowWidth = 993;

        active = parseInt(this.activeSlide);

        @Watch('activeSlide')
        setActive() {
            this.active = this.activeSlide;
        }

        @Watch('active', { immediate: true })
        setCurrent() {
            this.$emit('changeCurrent', this.active);
        }

        get slickRef() {
            return this.$refs[this.slickId + 'glide'];
        }

        mounted() {
            this.$nextTick(() => {
                if (this.slickRef !== undefined) {
                    // @ts-ignore
                    //this.slickRef.update();
                }
            });
            this.windowWidth = window.innerWidth;
        }

        @Watch('items')
        reinit() {
            // @ts-ignore
            //this.slickRef.update();
        }

        goToSlide(index) {
            this.active = index;
            this.$emit('changeCurrent', index);
        }

        // setActiveSlide(event, slick, currentSlide) {
        //     this.$emit("changeCurrent", currentSlide);
        // }
        slide(i) {
            //@ts-ignore
            this.$refs.glide.go('=' + i);
        }
        left() {
            //@ts-ignore
            this.$refs.glide.go('<');
        }

        right() {
            //@ts-ignore
            this.$refs.glide.go('>');
        }

        emitSliderEvent(e) {
            if (e.direction === '<') {
                this.$emit('sliderBack');
            } else if (e.direction === '>') {
                this.$emit('sliderForward');
            }
        }

        get focusAt() {
            if (this.FocusAt) {
                return this.FocusAt;
            } else {
                if (this.countSlides % 2 === 0) {
                    return '0';
                } else {
                    return 'center';
                }
            }
        }
    }
