
    import {Component, Prop, Vue} from 'nuxt-property-decorator';
    import {AvailableGroups, AvailableTypes, ComponentMeta} from '@/components/editor/config/types';

    export const meta: ComponentMeta = {
        title: 'Отступ',
        options: {
            margin: {
                type: AvailableTypes.object,
                title: 'Брейкпоинты',
                item: {
                    xs: {type: AvailableTypes.string, title: 'small'},
                    sm: {type: AvailableTypes.string, title: '576px'},
                    md: {type: AvailableTypes.string, title: '768px'},
                    lg: {type: AvailableTypes.string, title: '992px'},
                    xl: {type: AvailableTypes.string, title: '1200px'},
                },
                default: {
                    xs: '0',
                    sm: '0',
                    md: '0',
                    lg: '0',
                    xl: '0'
                }
            },
        },
        style: {'min-height': '1rem', position: 'relative', 'z-index': 4, background: '#ffd0d0'},
        group: AvailableGroups.Другое
    };

    @Component({})
    export default class MarginBlock extends Vue {
        @Prop() margin;
        @Prop() component;

        getStyle(margin) {
            return {'margin-top': margin, 'margin-bottom': margin}
        }
    }
