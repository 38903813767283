
    import { Component, Prop } from 'nuxt-property-decorator';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import { PageComponentOptions } from '@/store/page';
    import swipe from '@/directives/swipe';
    import striptags from 'striptags';
    import SliderBullets from '@/components/common/SliderBullets.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export const meta: ComponentMeta = {
        title: 'Галлерея с текстом',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.string
            },
            groups: {
                title: 'Группы',
                type: AvailableTypes.array,
                item: {
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    img: {
                        type: AvailableTypes.image,
                        title: 'Изображение'
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Описание'
                    }
                }
            }
        },
        group: AvailableGroups.Галерея
    };

    @Component({
        components: { ImageComponent, InlineSlider, SliderWrapper, SliderBullets },
        directives: { swipe }
    })
    export default class GalleryWithText extends PageBlockMixin {
        @Prop(Array) groups: PageComponentOptions[];
        @Prop({ default: 2 }) columns;

        striptags = striptags;

        get columnsItems() {
            let columnedItems = {};
            if (this.groups) {
                this.groups.forEach((group, index) => {
                    let obGr = { ...group };
                    obGr.dataIndex = index;
                    if (columnedItems[index % this.columns] === undefined) {
                        columnedItems[index % this.columns] = [obGr];
                    } else {
                        columnedItems[index % this.columns].push(obGr);
                    }
                });
            }

            return columnedItems;
        }

        getColumnBsLg() {
            return `${12 / this.columns}`;
        }
    }
