
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import PaletteMixin from '@/mixins/paletteMixin';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';

    export const meta: ComponentMeta = {
        title: 'Блок с одним изображением',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.editor,
                default:
                    'Пример нашей крутости!<br>' +
                    'Для срочного заказа за 2 дня<br>' +
                    'произвели 5000 пледов'
            },
            image: {
                title: 'Изображения',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/OneImageBlock/plaid-time.png'
                }
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    'Популярный размер 130х170 рекомендуем делать<br>' +
                    'пледы по длинной стороне не более 200см'
            },
            additionalClass: {
                type: AvailableTypes.string,
                title: 'Класс-обёртка блока',
                default: 'size-block__wrap_time'
            }
        },
        group: AvailableGroups.Изображение
    };

    @Component({
        components: { OptionsFieldsInlineEditor, ImageComponent }
    })
    export default class ImageOneBlock extends mixins(PageBlockMixin, PaletteMixin) {
        @Prop() component;
        @Prop() head;
        @Prop() image;
        @Prop() text;
        @Prop({ default: '' }) additionalClass;
    }
