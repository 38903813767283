
    import { Component, mixins, Prop, Vue } from 'nuxt-property-decorator';
    import { namespace } from 'vuex-class';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import BaseForm from '@/components/blocks/base/BaseForm.vue';
    import Modal from '@/components/blocks/Modal.vue';
    import { makeid } from '@/components/editor/utils/editor';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableTypes } from '@/components/editor/config/types';
    import ResultModal from '@/components/blocks/ResultModal.vue';

    const entities = namespace('entities');

    export const meta = {
        title: 'Форма',
        options: {
            form: {
                type: AvailableTypes.select,
                title: 'Форма',
                values: {
                    type: AvailableTypes.apiSource,
                    path: '/entities/forms/',
                    method: 'get',
                    conditions: {},
                    mappings: {
                        value: '_id',
                        text: 'options.name'
                    }
                }
            },
            modal: {
                type: AvailableTypes.boolean,
                title: 'Всплывающая',
                default: false
            },
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Что нужно делать, когда нужного цвета нет?'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'Минимальный тираж для выкраса полотна по пантону - 1000 футболок'
            }
        },
        inner: true
    };

    @Component({
        components: {
            ResultModal,
            OptionsFieldsInlineEditor,
            Modal,
            BaseForm,
            ImageComponent,
            Button
        }
    })
    export default class Form extends mixins(PageBlockMixin, Vue) {
        @Prop() form;
        @Prop() component;
        @Prop() modal;
        @Prop() isFullColorButton?;
        @Prop() borderColorButton?;
        @Prop() buttonText?;
        @Prop() isSmallButton?;
        @Prop() centered;
        @Prop() longInput;
        @Prop() inputCenter;
        @Prop() subscribe;
        @entities.Action fetchOne;
        @entities.State prefetchedEntities;

        formData = null;
        formId = '';
        submitButtonText = '';
        modalId = 'modalForm';
        ismodal = 'display';
        modalData = null;

        created() {
            if (
                this.prefetchedEntities &&
                Array.isArray(this.prefetchedEntities['forms'])
            ) {
                const prefetchedForm = this.prefetchedEntities['forms'].find(
                    ({ id }) => id === this.form
                );
                this.formData = prefetchedForm && prefetchedForm.options.form;
            }
        }

        async beforeMount() {
            this.modalId += makeid(16);
            await this.fetchForm();
        }

        async beforeUpdate() {
            this.fetchForm();
        }

        async fetchForm() {
            if (
                (this.form && !this.formData) ||
                (this.formData && this.formId !== this.form)
            ) {
                const form = await this.fetchOne({
                    id: this.form,
                    name: 'forms'
                });
                if (form) {
                    if (form.options.modal) {
                        let modal = await this.fetchOne({
                            id: form.options.modal,
                            name: 'modals'
                        });
                        this.modalData = modal.options;
                    }
                    this.formData = form.options.form;
                    this.formId = form.id;
                }
            }
        }

        click(form) {
            this.$bvModal.show(form);
        }

        get isBigModal() {
            return this.head.length > 0 || this.text.length > 0;
        }

        get resultModalId() {
            return 'result-modifier' + Math.floor(Math.random() * (1000 - 1));
        }
    }
