var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.groups)?_c('section',{staticClass:"gallery-w-text"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"gallery-w-text__title"},[_vm._v(_vm._s(_vm.head))]),_vm._v(" "),_c('SliderWrapper',{attrs:{"slides":_vm.groups},scopedSlots:_vm._u([{key:"default",fn:function({
                    slides,
                    prev,
                    next,
                    getClass,
                    goToSlide,
                    currentIndex
                }){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"7"}},[_c('div',{staticClass:"gallery-w-text__slider"},_vm._l((slides),function({ title, text, img },index){return _c('div',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],key:index,staticClass:"gallery-w-text__slider__content",class:getClass(index)},[_c('image-component',{staticClass:"gallery-w-text__slider__img",attrs:{"img":img}}),_vm._v(" "),_c('SliderBullets',{staticClass:"gallery-w-text__slider__bullets d-lg-none",attrs:{"slides-amount":slides.length,"current":currentIndex}}),_vm._v(" "),_c('h3',{staticClass:"gallery-w-text__slider__title"},[_vm._v("\n                                    "+_vm._s(title)+"\n                                ")]),_vm._v(" "),_c('div',{staticClass:"gallery-w-text__slider__text",domProps:{"innerHTML":_vm._s(_vm.striptags(text, ['a']))}})],1)}),0)]),_vm._v(" "),_c('b-col',{staticClass:"d-none d-lg-block gallery-w-text__list",attrs:{"lg":"4","offset-lg":"1"}},[_c('b-row',_vm._l((_vm.columnsItems),function(column,i){return (_vm.columnsItems)?_c('b-col',{key:i,attrs:{"lg":_vm.getColumnBsLg()}},_vm._l((column),function(item,i){return _c('div',{key:i,staticClass:"gallery-w-text__preview-item",class:{
                                        active: item.dataIndex === currentIndex
                                    },on:{"click":function($event){return goToSlide(item.dataIndex)}}},[_c('div',{staticClass:"gallery-w-text__img-wrap gallery-w-text__img-wrap_small"},[(item.img)?_c('image-component',{staticClass:"gallery-w-text__img",attrs:{"img":item.img}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gallery-w-text__img-overlay"})],1),_vm._v(" "),(item.title)?_c('span',{staticClass:"gallery-w-text__preview-item__title",attrs:{"href":item.title}},[_vm._v("\n                                        "+_vm._s(item.title)+"\n                                    ")]):_vm._e()])}),0):_vm._e()}),1)],1)],1)]}}],null,false,725046577)})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }